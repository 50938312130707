import { graphql } from "gatsby";
import { propDataTypes, blogDataDefaults } from "../../../../prop-types";
import SupportDoc from "../../../../layouts/WithSideBarPage/_supportDocPage";

const LicenseChange2020 = ({ data }) => (
  <SupportDoc
    frontmatter={data.markdownRemark.frontmatter}
    htmlAst={data.markdownRemark.htmlAst}
    afterAccentTitleToolkit={false}
    withComments={false}
    isSubheaderWithoutLine
  />
);

LicenseChange2020.propTypes = propDataTypes;
LicenseChange2020.defaultProps = blogDataDefaults;

export default LicenseChange2020;

export const query = graphql`
  query licenseChange2020(
    $path: String!
  ) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        seo {
          description
          link
          title
          noForRobots
        }
        path
        bannerTitle
        bannerSubTitle
        href
        featureToolkit
        featureTitleToolkit
        featureButtonTextToolkit
        sidebarTitle
      }
    }
  }
`;
